import React, { useState } from "react"
import { Upload, Modal, message, Button } from "antd"

import { IMG_API, MAX_UPLOAD_NUM, LOGIN_URL } from "../utils/constant"
import uploadStyles from "./Upload.module.css"
import inbox from "../images/inbox.svg"
import qr from "../images/qr.png"

export default () => {
  const [previewImage, setPreviewImage] = useState("")
  const [previewVisible, setPreviewVisible] = useState(false)
  const [imgList, setImgList] = useState([])
  const [successNum, setSuccessNum] = useState(0)
  const [loginVisible, setLoginVisible] = useState(false)
  const [loginLoading, setLoginLoading] = useState(false)
  const [modalContent, setModalContent] = useState({});

  const handleCancel = () => setPreviewVisible(false)

  const handlePreview = async file => {
    const { status } = file
    if (status === "done") {
      file.url = file.response.data
      setPreviewVisible(true)
      setPreviewImage(file.url)

      // 复制 url 触发
      document.addEventListener("copy", function copyCall(e) {
        e.preventDefault()
        e.clipboardData.setData("text/html", file.url)
        e.clipboardData.setData("text/plain", file.url)
        document.removeEventListener("copy", copyCall)
      })
      document.execCommand("copy")

      message.success("复制图片链接成功")
    }
  }


  const handleLoginOk = () => {
    setLoginLoading(true);
    setTimeout(() => {
      setLoginLoading(false);
      setLoginVisible(false);
    }, 3000);
  };

  const handleLoginCancel = () => {
    setLoginVisible(false);
  };


  const handleDraggerChange = info => {
    const { status } = info.file
    const { file, fileList } = info
    // 每张图片成功都会进来
    if (status === "done") {
      if (!!file.response && file.response.code == 200) {
        file.url = file.response.data
        // 减到0说明图片都上传成功了
        setSuccessNum(successNum - 1)
        if (successNum - 1 === 0) {
          message.success(`全部图片上传成功`)
        }
      } else {
        if (!!file.response && !!file.response.message) {
          if (file.response.code === 1017) {
            setLoginVisible(true);
            setModalContent({ "url": LOGIN_URL, message: file.response.message, text: "登录/注册" });
          } else if (file.response.code === 1018) {
            setLoginVisible(true);
            setModalContent({ "url": "/pricing", message: file.response.message, text: "升级" });
          } else {
            message.error(file.response.message, 5);
          }
        } else {
          message.error(`${info.file.name} 上传失败，如反复出现请联系管理员`)
        }
      }
    } else if (status === "error") {
      if (!!file.response && !!file.response.message) {
        if (file.response.code === 1017) {
          setLoginVisible(true);
          setModalContent({ "url": LOGIN_URL, message: file.response.message, text: "登录/注册" });
        } else if (file.response.code === 1018) {
          setLoginVisible(true);
          setModalContent({ "url": "/pricing", message: file.response.message, text: "升级" });
        } else {
          message.error(file.response.message, 5);
        }
      } else {
        message.error(`${info.file.name} 上传失败，如反复出现请联系管理员`)
      }
    }
    setImgList([...fileList])
  }

  // 上传前清空之前的图片列表
  const handleBeforeUpload = (file, fileList) => {
    if (!window.localStorage.getItem("contract-dialog-show")) {
      Modal.info({
        title: '用户须知',
        content: (
          <div>
            <p>图壳自 2019 年运行至今已有 2 年之久，为广大用户提供<span style={{ color: "red", fontWeight: "bold" }}> 免费、稳定、可靠 </span>的存储图片能力，感谢大家陪伴图壳一路走来！</p>
            <p>为更长久的提供您图片服务，计划推出付费版本，第一波用户将提供<span style={{ color: "red", fontWeight: "bold" }}> 最优惠 </span>的价格，扫码备注”<span style={{ color: "red", fontWeight: "bold" }}> 图壳 </span>“了解详情。</p>
            <p><img src={qr} className={uploadStyles.qrImg} /></p>
          </div>
        ),
        onOk: function () {
          window.localStorage.setItem("contract-dialog-show", true);
        }
      });
    }
    // 每张图片都会进来
    if (fileList.length > MAX_UPLOAD_NUM) {
      message.error(`上传图片超过 ${MAX_UPLOAD_NUM} 张`)
      return Promise.reject(`上传图片超过 ${MAX_UPLOAD_NUM} 张`)
    }
    setSuccessNum(fileList.length)
    setImgList([])
  }

  return (
    <div className={uploadStyles.container}>
      <Upload.Dragger
        withCredentials={true}
        name="file"
        accept="image/*"
        multiple
        listType="picture"
        action={IMG_API}
        onChange={handleDraggerChange}
        onPreview={handlePreview}
        beforeUpload={handleBeforeUpload}
        maxCount={1}
        fileList={imgList}
        className={uploadStyles.dragger}
      >
        <p className="ant-upload-drag-icon" style={{ marginBottom: 0 }}>
          <img alt="" src={inbox} className={uploadStyles.icon} />
        </p>
        <p className={uploadStyles.word}>点击或者拖拽文件进行上传</p>
        <p className={uploadStyles.word}>支持同时上传最多 {MAX_UPLOAD_NUM} 张图片</p>
      </Upload.Dragger>
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
      <Modal
        visible={loginVisible}
        title="登录提示框"
        onOk={handleLoginOk}
        onCancel={handleLoginCancel}
        content={modalContent}
        footer={[
          <Button key="back" onClick={handleLoginCancel}>
            取消
          </Button>,
          <Button
            style={{ marginLeft: '8px' }}
            key="link"
            href={modalContent.url}
            type="primary"
            loading={loginLoading}
            onClick={handleLoginOk}
          >
            {modalContent.text}
          </Button>,
        ]}
      >
        <p>{modalContent.message}</p>
      </Modal>
    </div>
  )
}