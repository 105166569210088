import React from "react"

import aboutStyles from "./About.module.css"
import ban from "../images/ban.png"
import contact from "../images/contact.png"
import rights from "../images/rights.png"
import ucloud from "../images/ucloud.png"

export default () => {
  return (
    <div className={aboutStyles.container}>
      <section className={aboutStyles.rights}>
        <div className={aboutStyles.rightsContainer}>
          <div className={aboutStyles.titleContainer}>
            <h2 className={aboutStyles.title}>01</h2>
            <h3 className={aboutStyles.subTitle}>权利声明</h3>
          </div>
          <p className={aboutStyles.content}>
            <a
              rel="noopener noreferrer"
              href="https://imgkr.com/"
              target="_blank"
            >
              图壳
            </a>
            是一个免费提供存储图片能力的平台
          </p>
          <p>用户上传的内容版权归用户所有，并承担相应责任</p>
          <p>我们保留删除图片并举报违规上传者的权利</p>
          <strong>用户使用图壳即为默认同意本声明</strong>
        </div>
        <img alt="" src={rights} className={aboutStyles.rightsImg} />
      </section>

      <section className={aboutStyles.ban}>
        <img alt="" src={ban} className={aboutStyles.banImg} />

        <div className={aboutStyles.banContainer}>
          <div className={aboutStyles.titleContainer}>
            <h2 className={aboutStyles.title}>02</h2>
            <h3 className={aboutStyles.subTitle}>禁止行为</h3>
          </div>
          <p>严禁上传及分享如下类型的图片：</p>
          <ul>
            <li>含有色情、暴力、宣扬恐怖主义的图片</li>
            <li>侵犯版权、未经授权的图片</li>
            <li>其他违反中华人民共和国法律的图片</li>
            <li>其他违反香港法律的图片</li>
          </ul>
        </div>
      </section>

      <section className={aboutStyles.contact}>
        <div className={aboutStyles.contactContainer}>
          <div className={aboutStyles.titleContainer}>
            <h2 className={aboutStyles.title}>03</h2>
            <h3 className={aboutStyles.subTitle}>联系我们</h3>
          </div>
          <p>联系邮箱：contact@imgkr.com</p>
        </div>
        <img alt="" src={contact} className={aboutStyles.contactImg} />
      </section>

      <section className={aboutStyles.support}>
        <img alt="" src={ucloud} className={aboutStyles.supportImg} />

        <div className={aboutStyles.supportContainer}>
          <div className={aboutStyles.titleContainer}>
            <h2 className={aboutStyles.title}>04</h2>
            <h3 className={aboutStyles.subTitle}>技术支持</h3>
          </div>
          <p>
            本网站由{" "}
            <a
              rel="noopener noreferrer"
              href="https://urlify.cn/ae6bui"
              target="_blank"
            >
              UCloud
            </a>{" "}
            提供图片存储和鉴黄
          </p>
        </div>
      </section>
    </div>
  )
}
