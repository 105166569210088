import React from "react"

import screenStyles from "./Screen.module.css"

export default () => {
  return (
    <>
      <h1 className={screenStyles.title}>
        <span className={screenStyles.name}>图壳</span>，图片最稳定的家
      </h1>

      <div className={screenStyles.eggWrapper}>
        <div className={screenStyles.chickHead}>
          <div className={screenStyles.eyeDiv}></div>
          <div className={screenStyles.eyeDiv}></div>
          <div className={screenStyles.beakDiv}></div>
        </div>
        <div className={screenStyles.eggDiv}></div>
        <div className={screenStyles.eggTop}></div>
      </div>

      <a href="#upload" className={screenStyles.uploadBtn}>
        上传图片
      </a>
    </>
  )
}
