import React from "react"

import { BackTop } from "antd"
import { Helmet } from "react-helmet"

import indexStyles from "./index.module.css"
import Navbar from "../components/Navbar"
import Screen from "../components/Screen"
import About from "../components/About"
import Upload from "../components/Upload"
import Footer from "../components/Footer"

export default () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="keywords" content="路过图床, 免费公共图床, 图片上传,图片外链, 原图保存, 全球CDN加速,新浪图床,免费图床,开源图床,个人图床,图床,php图床,图片外链,sm.ms,图床网" />
        <meta name="description" content="图壳诞生于2019年，是一款免费、安全、可靠的图片管理系统（简称图床），可支持多图上传、粘贴上传、URL上传，图片压缩、图片鉴黄、图片CDN、图床API等多种功能" />
        <meta name="imgkr" content="contact@imgkr.com"/>
        <title>图壳，图片最稳定的家 | 免费、安全、可靠图床</title>
        <link rel="canonical" href="https://imgkr.com/" />
      </Helmet>
      <BackTop />
      <Navbar />
      <section id="home" className={indexStyles.content}>
        <Screen />
      </section>

      <section id="upload" className={indexStyles.upload}>
        <Upload />
      </section>

      <section id="about" className={indexStyles.about}>
        <About />
      </section>

      <footer id="foot" className={indexStyles.foot}>
        <Footer />
      </footer>
    </div>
  )
}
